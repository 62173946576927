<template>
    <el-dialog title="详情" :visible.sync="detail.visible" @open="open"
        destroy-on-close center append-to-body width="1000px">
        <x-detail label-width="140px" :entity="entity">
            <x-detail-item prop="kaHao" label="卡号" />
            <x-detail-item prop="xingMing" label="姓名" />
            <x-detail-item prop="bianDongJiFen" label="变动积分" />
            <x-detail-item prop="bianDongLX" label="变动类型" />
            <x-detail-item prop="bianDongSJ" label="变动时间" />
            <x-detail-item prop="caoZuoRen" label="操作人" />
            <x-detail-item prop="shouJiHao" label="手机号" />
        </x-detail>
    </el-dialog>
</template>

<script>
    import {getDetail} from "@/service/hygl/JiFenJiLu";
    import XDetailBase from "@/components/x/XDetailBase";

    export default {
        mixins: [XDetailBase],
        beforeCreate() {
            this.defaultModel = {
                kaHao: "", // 卡号
                xingMing: "", // 姓名
                bianDongJiFen: "", // 变动积分
                bianDongLX: "", // 变动类型
                bianDongSJ: "", // 变动时间
                caoZuoRen: "", // 操作人
                shouJiHao: "", // 手机号
            };
            this.getDetailService = getDetail;
        }
    }
</script>

<style scoped>

</style>