<template>
    <el-dialog :title="title" :visible.sync="editor.visible" @open="open"
            :close-on-click-modal="false" destroy-on-close center append-to-body width="1000px">
        <el-form ref="form" :model="form" :rules="rules" class="x-edit">
            <el-form-item label="卡号" prop="kaHao" >
                <el-input v-model="form.kaHao" size="small" maxlength="36" show-word-limit/>
            </el-form-item>
            <el-form-item label="姓名" prop="xingMing" >
                <el-input v-model="form.xingMing" size="small" maxlength="36" show-word-limit/>
            </el-form-item>
            <el-form-item label="变动积分" prop="bianDongJiFen" >
                <el-input v-model="form.bianDongJiFen" size="small" maxlength="" show-word-limit/>
            </el-form-item>
            <el-form-item label="变动类型" prop="bianDongLX" >
                <el-input v-model="form.bianDongLX" size="small" maxlength="" show-word-limit/>
            </el-form-item>
            <el-form-item label="变动时间" prop="bianDongSJ">
                <el-date-picker v-model="form.bianDongSJ" size="small" type="datetime" value-format="yyyy-MM-dd HH:mm:ss"/>
            </el-form-item>
            <el-form-item label="操作人" prop="caoZuoRen" >
                <el-input v-model="form.caoZuoRen" size="small" maxlength="36" show-word-limit/>
            </el-form-item>
            <el-form-item label="手机号" prop="shouJiHao" >
                <el-input v-model="form.shouJiHao" size="small" maxlength="36" show-word-limit/>
            </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
            <el-button type="primary" @click="save" icon="el-icon-document-add">保 存</el-button>
            <el-button @click="cancel" icon="el-icon-switch-button">取 消</el-button>
        </span>
    </el-dialog>
</template>

<script>
    import {add, update, getUpdate} from "@/service/hygl/JiFenJiLu";
    import XEditBase from "@/components/x/XEditBase";
    import {ruleBuilder} from '@/util/validate';

    export default {
        mixins: [XEditBase],
        beforeCreate() {
            let {required} = ruleBuilder;
            this.rules = {
                kaHao: [], // 卡号
                xingMing: [], // 姓名
                bianDongJiFen: [], // 变动积分
                bianDongLX: [], // 变动类型
                bianDongSJ: [], // 变动时间
                caoZuoRen: [], // 操作人
                shouJiHao: [], // 手机号
            }
            this.titlePrefix = '积分记录';
            this.defaultForm = {
                id: null,
                kaHao: "", // 卡号
                xingMing: "", // 姓名
                bianDongJiFen: "", // 变动积分
                bianDongLX: "", // 变动类型
                bianDongSJ: "", // 变动时间
                caoZuoRen: "", // 操作人
                shouJiHao: "", // 手机号
            };
            this.addService = add;
            this.updateService = update;
            this.getUpdateService = getUpdate;
        }
    }
</script>

<style scoped>

</style>